import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Shimmer from '../../components/Shimmer';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { MdOutlinePlayCircleFilled, MdOutlinePlayCircleOutline } from "react-icons/md";
import ReactPlayer from 'react-player';

import api from '../../services/api';

import logo from '../../assets/images/logo.png';
import base from '../../assets/images/blank-square.png';

export default function ExperienceCourse() {

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [loading, setLoading] = useState(true);
    const [loading_page, setLoadingPage] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [logo_hotel, setLogo] = useState('');
    const [courses, setCourses] = useState([]);
    const [courses_modules, setCoursesModules] = useState([]);
    const [current_content, setCurrentContent] = useState([]);

    const { id } = useParams();

    async function loadPage(){
        try{
            const response = await api.post(`/experience`, { id: 'b919192e-d178-42db-956f-dbfe74d012cc' });
            const data = response.data.data;
            setLogo(data.user.logo);
            setLoading(false);
            setLoadingPage(false);
        }catch(err){
            setLoading(false);
            setLoadingPage(false);
        }
    }

    async function loadCourses(){
        setLoading(true);
        try{
            const response = await api.post(`/experience-course`, { id });
            setCourses(response.data.data.courses);
            setCoursesModules(response.data.data.modules);
            getContent(response.data.data.modules[0].content[0]);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function getContent(arr){
        setCurrentContent(arr);
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        loadCourses();
        loadPage();
    }, []);

    return (
        <div className="landing-page-wrapper">
            {loading_page &&
            <div id="loader">
                <div className="loader-content"><div className="sbl-circ-path"></div></div>
            </div>}
            <header className="app-payment bg-light">
                <div className="container p-0">
                    <div className="landing-logo-container">
                        <div className="horizontal-logo">
                            <div className="header-logo d-flex align-items-center justify-content-center gap-5">
                                {logo_hotel ? <img src={logo_hotel} className="logo" /> : <img src={logo} className="logo" />}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="main-content landing-main hotel-title">
                {!loading &&
                <section className="section" data-aos="fade-up">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-xxl-6 col-md-12 col-lg-8 m-auto">
                                <Link to="/experience" className="btn-back">
                                    <span><RiArrowLeftSLine /></span>
                                    <span>Voltar</span>
                                </Link>

                                <div className="pb-0">
                                    <h4 className="fs-18 fw-semibold mb-3">{courses.title} - <span className="text-black">{current_content?.title}</span></h4>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="embed-container mb-4">
                                            <ReactPlayer
                                                url={current_content?.video}
                                                controls={true}
                                                playsinline={true}
                                                playing={true}
                                                frameBorder="0"
                                                allowFullScreen
                                            />
                                        </div>
                                        <div dangerouslySetInnerHTML={{__html: current_content?.description}} />
                                        <hr className="border border-opacity-50" />
                                    </div>
                                    {courses_modules.map((item, index) => (
                                    <div className="col-xl-12 mb-3" key={item.id}>
                                        <h5 className="fs-18 fw-semibold text-black mb-2">{item.title}</h5>
                                        <div className="p-0">
                                            <ul className="list-group list-custom">
                                                {Array.isArray(courses_modules[index].content) && <>
                                                {courses_modules[index].content.map(item_link => (
                                                <li className={`list-group-item ${current_content?.id===item_link.id ? ' play-active' : ''}`} key={item_link.id} onClick={() => {
                                                    getContent(item_link);
                                                }}>
                                                    <div className="d-flex gap-2 flex-wrap align-items-center">
                                                        <div className="list-play">
                                                            {current_content?.id===item_link.id ? <MdOutlinePlayCircleFilled /> : <MdOutlinePlayCircleOutline />}
                                                        </div>
                                                        <div className="flex-fill">
                                                            <h4 className="fs-15 fw-semibold mb-0 op-1-1">{item_link.title}</h4>
                                                        </div>
                                                        <div className="list-arrow">
                                                            <RiArrowRightSLine />
                                                        </div>
                                                    </div>
                                                </li>))}
                                                </>}
                                            </ul>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
            </div>

        </div>
    );
}